import { ref } from "vue";
import axios from "axios";
import { baseURL } from "@/utils/helpers";
import { toggleFlashMessage } from "@/hooks/useToast";

const isLoading = ref(false);
const requestPercentage = ref(0);
export const hasModal = ref(false);
const loadingTask = ref<string | null>(null);

axios.defaults.baseURL = baseURL;

axios.defaults.headers = {
  Accept: "application/json",
} as any;

// Set onDownloadProgress globally
axios.defaults.onDownloadProgress = (progressEvent) => {
  if (progressEvent.total) {
    //const percentage = (progressEvent.loaded / progressEvent?.total || 1) * 100;
    const percentage = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    requestPercentage.value = percentage;
    if (percentage === 100) {
      setTimeout(() => {
        requestPercentage.value = 0;
      }, 400);
    }
  }
};

// Set onUploadProgress globally
axios.defaults.onUploadProgress = (progressEvent) => {
  if (progressEvent.total) {
    //const percentage = (progressEvent.loaded / progressEvent?.total || 1) * 100;
    const percentage = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    requestPercentage.value = percentage;
    if (percentage === 100) {
      setTimeout(() => {
        requestPercentage.value = 0;
      }, 400);
    }
  }
};

const token = localStorage.getItem("token");
let pendingRequests = 0;
const resolveRequest = () => {
  isLoading.value = false;
  loadingTask.value = null;
  if (hasModal.value) {
    const el = document.querySelector("#alert-container");
    if (el) {
      el.remove();
    }
  }
};
export const setHttpBackgroundTask = (task: string | null) => {
  loadingTask.value = task;
};
axios.interceptors.request.use(
  (config) => {
    const currentPath = window.location.pathname;
    if (
      currentPath.includes("/rw") ||
      currentPath.includes("/zm") ||
      currentPath.includes("/mw")
    ) {
      config.headers["country"] = currentPath;
    } else {
      if (currentPath.includes("/admin/")) {
        const lang = localStorage.getItem("lang");
        if (lang) {
          config.headers["country"] = "/" + lang;
        }
      }
    }
    pendingRequests++;
    isLoading.value = true;
    return config;
  },
  (error) => {
    pendingRequests--;
    resolveRequest();
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    pendingRequests--;
    if (pendingRequests === 0) {
      resolveRequest();
    }
    return response;
  },
  (error) => {
    pendingRequests--;
    resolveRequest();
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      if (location.pathname !== "/admin/login") {
        if (location.pathname.indexOf("/admin/") !== -1) {
          location.replace("/admin/login");
        }
      }
    }
    if (error.response.status == 500) {
      toggleFlashMessage({
        type: "danger",
        text: "Error. Contact system administrator",
      });
    }
    return Promise.reject(error);
  }
);

if (token) {
  axios.defaults.headers = {
    ...axios.defaults.headers,
    ...{ Authorization: `Bearer ${token}` },
  } as any;
}
const http = axios;

export function useHttp() {
  return {
    http,
    isLoading,
    loadingTask,
    resolveRequest,
    setHttpBackgroundTask,
    hasModal,
    requestPercentage,
  };
}
