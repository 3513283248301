import { ref } from "vue";
import { UserRecord } from "/#/user";

const user = ref<UserRecord | null>(null);
const isAuthOpen = ref(false);
export function useAuth() {
  const setCurrentUser = (row: UserRecord) => {
    user.value = row;
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    if (location.href.indexOf("/admin/") !== -1)
      window.location.replace("/admin/login");
    else window.location.replace("/");
  };
  const toggleAuth = () => {
    isAuthOpen.value = !isAuthOpen.value;
  };
  return { user, setCurrentUser, handleLogout, isAuthOpen, toggleAuth };
}
