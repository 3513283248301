export const appRoutes = [
  {
    path: "/",
    name: "HomePage",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/HomeView.vue"),
    meta: {
      title: "Welcome!",
    },
  },
  {
    path: "/network-support",
    name: "NetworkSupport",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/SupportView.vue"),
    meta: {
      title: "Report an Issue",
    },
  },
  {
    path: "/company/about",
    name: "AboutPage",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/AboutView.vue"),
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/company/leadership",
    name: "TeamPage",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/TeamPage.vue"),
    meta: {
      title: "Company's Leadership",
    },
  },
  {
    path: "/company/solutions",
    name: "SolutionsView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/SolutionsView.vue"),
    meta: {
      title: "Efashe Solutions",
    },
  },
  {
    path: "/company/contact-us",
    name: "ContactView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/ContactView.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/company/gallery",
    name: "GalleryView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/GalleryView.vue"),
    meta: {
      title: "Gallery",
    },
  },
  {
    path: "/company/careers",
    name: "VacanciesView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/VacanciesView.vue"),
    meta: {
      title: "Vacancies",
    },
  },
  {
    path: "/events",
    name: "EventsView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/EventsView.vue"),
    meta: {
      title: "Events",
    },
  },
  {
    path: "/events/:slug",
    name: "EventDetails",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/EventDetails.vue"),
    meta: {
      title: "Events",
    },
  },
  {
    path: "/support/faq",
    name: "FAQView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/FAQView.vue"),
    meta: {
      title: "Frequently Asked Questions",
    },
  },
  {
    path: "/support/legal",
    name: "LegalView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/LegalView.vue"),
    meta: {
      title: "Legal, Terms & Conditions and Privacy Policy",
    },
  },
];
