import { ref } from "vue";
import { useHttp } from "@/hooks/useHttp";

const { http } = useHttp();
const cachedData = ref<any>({});

const setCachedData = <T>(data: T) => {
  for (const key in data) {
    cachedData.value[key] = data[key];
  }
};

export function useFrontend() {
  const getPublicContent = (url: string) => {
    http.get(url).then((response) => {
      setCachedData(response.data);
    });
  };
  const getCachedData = (keys: Array<string> | string) => {
    if (Array.isArray(keys)) {
      const result: any = {};
      keys.forEach((key) => {
        result[key] = cachedData.value[key];
      });
      return result;
    }
    return cachedData.value[keys];
  };
  return { getPublicContent, getCachedData, setCachedData };
}
