export const adminRoutes = [
  {
    path: "/admin/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/admin/views/LoginView.vue"),
    meta: {
      title: "AdminLogin",
    },
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/admin/views/DashboardView.vue"),
    meta: {
      title: "Dashboard",
      requireAuth: true,
    },
  },
  {
    path: "/admin/gallery",
    name: "PhotoGallery",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/admin/views/PhotoGallery.vue"),
    meta: {
      title: "Photo Gallery",
      requireAuth: true,
    },
  },
  {
    path: "/admin/builder/:slug",
    name: "Builder",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/admin/views/BuilderView.vue"),
    meta: {
      title: "Builder",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/events",
    name: "EventsListing",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/admin/views/events/IndexView.vue"
      ),
    meta: {
      title: "Events Listing",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/events/categories",
    name: "EventsCategories",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/admin/views/events/CategoriesView.vue"
      ),
    meta: {
      title: "Events Categories",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/events/:action/:slug?",
    name: "EventCreator",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/admin/views/events/CreateView.vue"
      ),
    meta: {
      title: "Create Or Edit Post",
      requiresAdminAuth: true,
    },
  }
];
