export const generateFormData = (obj: any) => {
  const fd = new FormData();
  for (const key in obj) {
    if (obj[key] !== null && typeof obj[key] !== "undefined") {
      if (typeof obj[key] === "object")
        fd.append(key, JSON.stringify(obj[key]));
      else fd.append(key, obj[key]);
    }
  }
  return fd;
};

export const baseURL =
  process.env.NODE_ENV == "production"
    ? "/api/"
    : "http://127.0.0.1:8000/api/";

export const serverUrl =
  process.env.NODE_ENV == "production"
    ? "/api/"
    : "http://127.0.0.1:8000/";

export const getImageFromServer = (img: string) => {
  return `${serverUrl}uploads/${img}`;
};

export const formatNumber = (num: number | string) => {
  if (num.toString().indexOf(".") > -1) num = Number(num).toFixed(3);
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const encodeQuery = (url: string, data?: any) => {
  let query = "";
  for (const d in data) {
    if (data[d])
      query += encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
  }
  if (url.indexOf("?") > -1) {
    return `${url}${query.slice(0, -1)}`;
  } else {
    const freshUrl = url.split("?")[0];
    return `${freshUrl}?${query.slice(0, -1)}`;
  }
};

export const padNumber = (num: number, targetedLength = 3) => {
  const strNumber = num.toString();
  if (strNumber.length < targetedLength) {
    const padding = new Array(targetedLength - strNumber.length + 1).join("0");
    return padding + strNumber;
  }
};

export const titleFromSlug = (text: string) => {
  return text.split("_").join(" ");
};

export const slugFromTitle = (text: string) => {
  return text.toLowerCase().split(" ").join("_");
};

export const getFileIcon = (filename?: string | null | undefined) => {
  if (!filename) {
    return "/img/files/file.svg";
  } 
  const extension = filename.split(".").pop();
  return `/img/files/${extension}.svg`;
};

export const generateGoogleMapURL = (address: string) => {
  // Encode the address to make it URL safe
  const encodedAddress = encodeURIComponent(address);
  // Google Maps URL for a pin
  const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
  return googleMapsURL;
}
