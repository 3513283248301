import { ref } from "vue";
type Message = { type?: string; text?: string };
const hasFlashMessage = ref(false);
const message = ref<Message | null>();

const toggleFlashMessage = (msg: Message | null) => {
  hasFlashMessage.value = !hasFlashMessage.value;
  message.value = msg;
  setTimeout(() => (hasFlashMessage.value = !hasFlashMessage.value), 3000);
};

export { hasFlashMessage, toggleFlashMessage, message };
